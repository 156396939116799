import React from 'react';

export function FullWidthImage({ src, height }) {
  return (
    <div style={{
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100vw',
      height,
      backgroundImage: `url('${src}')`,
      backgroundPosition: '50% 0',
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'no-repeat',
      margin: '2rem 0',
    }} />
  )
}