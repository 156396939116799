import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path2/src/components/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ResponsiveImage = makeShortcode("ResponsiveImage");
const FullWidthImage = makeShortcode("FullWidthImage");
const BreakpointImage = makeShortcode("BreakpointImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Meet `}<em parentName="p">{`Little Martian`}</em>{`, a friendly being who has crash-landed on an unknown planet and urgently needs your help! Little Martian is a top-down survival game that confronts you with decisions that alter the trajectory of the game, and force you to confront the morality of your choices.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/20-08-24/player-wave.gif",
        "alt": "Little Martian waving"
      }}></img></p>
    <p>{`In the short term you must help Little Martian survive. In the long run you must find a way to repair the damaged craft so that Little Martian may return home.`}</p>
    <h2>{`World Generation`}</h2>
    <p>{`Terrain in Little Martian is generated procedurally, using three parameters: elevation, moisture, and temperature. For each tile, we calculate these parameters using a random noise algorithm, then use them to lookup a biome.`}</p>
    <p>{`For example, when the elevation is above sea-level, the moisture is low and the temperature is high then the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`desert`}</code>{` biome will be selected. However, if the moisture and temperature are both high then the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`warm forest`}</code>{` biome will be selected instead. It seems to give a pleasing mixture of barren, rocky areas and lush grasslands, such that the bare materials required for crafting early on in the game will all be fairly close by.`}</p>
    <ResponsiveImage src="/images/20-08-24/world-generation.png" ratio={0.6} width={['auto', '1800px']} height={['800px', '1200px']} mdxType="ResponsiveImage" />
    <p>{`We are intentionally keeping terrain generation simple for now, but we have plans to improve upon it once we have completed more of the basic game play, including:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Streams and rivers`}</em>{` – They will respect the topography of the terrain, flowing from areas to higher elevation into lakes, and possibly other rivers. Narrow streams may be crossed easily, but wider streams will be too deep to wade through.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Mineral veins`}</em>{` – Abundant in valuable materials, this biome will be rare and unlikely to be found until much later in the game.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Islands`}</em>{` – Cut off from the mainland by water too deep to wade through (unfortunately, Little Martian never learnt to swim), small islands containing rare elements required to craft more advanced machinery. If only you can find a way to reach them.`}</p>
      </li>
    </ul>
    <h2>{`Companions`}</h2>
    <p>{`At the time of the accident Little Martian was travelling alongside a trusted companion: either a dog, a cat, or a fox. When you begin a new game you will select which companion you want to share your adventure with. Each companion will have unique abilities, behaviours, and they will help you in different ways as you progress through the game.`}</p>
    <FullWidthImage src="/images/20-08-24/companions.png" height="480px" mdxType="FullWidthImage" />
    <p>{`We'll introduce the companions properly in a follow up post, and provide a sneak peek into their differing behaviours and skills, so watch this space!`}</p>
    <h2>{`Crafting`}</h2>
    <p>{`Crafting in Little Martian follows the same structure that is found in games such as `}<em parentName="p">{`Minecraft`}</em>{`: combine materials together in the correct quantities. In Little Martian – since the things you'll be crafting are more technological in nature – you'll need to find specific chemical elements, compounds, and alloys.`}</p>
    <p>{`For example, crafting a `}<item>{`processor`}</item>{` requires `}<item>{`silicon`}</item>{`, `}<item>{`brass`}</item>{` and `}<item>{`wire`}</item>{`, which is itself crafted from `}<item>{`copper`}</item>{`. There are currently 24 elements, 11 compounds, and 3 alloys, with many more planned. Here's a selection:`}</p>
    <BreakpointImage narrow="/images/20-08-24/elements-narrow.png" wide="/images/20-08-24/elements-wide.png" mdxType="BreakpointImage" />
    <p>{`We think this will put a greater emphasis on exploration of Unknown Planet, since elements will be found clustered together in specific areas or within certain biomes, therefore you will not be able to mine all the nearby ores with the hope of finding the right elements.`}</p>
    <h2>{`Climate Change`}</h2>
    <p>{`We're not going to say much right now about this feature because we'll cover it in far more detail in a future post as there's a lot to say about it. It's a feature we're really excited about, and we think you will be too once you see it in action. It's at the heart of what sets Little Martian apart from other survival games.`}</p>
    <BreakpointImage narrow="/images/20-08-24/climate-change-narrow.png" wide="/images/20-08-24/climate-change.png" mdxType="BreakpointImage" />
    <p>{`See how the terrain becomes more barren and arid, and oceans and lakes begin to recede. This will make it harder to find food and resources required to survive. `}</p>
    <h2>{`Who Are We?`}</h2>
    <p>{`We are team of three:`}</p>
    <p><em parentName="p">{`Jack`}</em>{` is in charge of play testing and thinking up hilarious ideas.`}</p>
    <p><em parentName="p">{`Charlie`}</em>{` is head of gameplay mechanics and contributes pixel art.`}</p>
    <p><em parentName="p">{`Craig`}</em>{` (that's me!) is lead developer, pixel artist, and the father of Jack and Charlie.`}</p>
    <p>{`I've been a frontend web developer for the past decade, and creating a video game started as a fun way for me to teach my sons about programming and software development. But as we began to build on the idea of Little Martian more and more we realised it was turning into the survival game that each of us so badly wish existed, so we're determined to see it through to the end.`}</p>
    <h2>{`Inspiration`}</h2>
    <p>{`Growing up playing on a ZX Spectrum as a child I still admire the style of its games, with vivid colours on predominantly black backgrounds due to the limitations of the hardware, whereby each 8x8 pixel 'block' could consist of only two colours. The graphics and style of Little Martian are meant to be a nod to that era, with a slight modern twist.`}</p>
    <FullWidthImage src="/images/20-08-24/zx-spectrum-games.png" height="384px" mdxType="FullWidthImage" />
    <p>{`The vast majority of sprites in Little Martian consist of a single colour (and black), though there are a few places where we have chosen to relax this constraint to improve clarity.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      