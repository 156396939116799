import React from 'react';
import { ItemIcon } from './Item';

export function Recipe(props) {
  const inputs = props.inputs ? props.inputs.split(',') : null;
  const outputs = props.outputs ? props.outputs.split(',') : null;

  return (
    <div className="Recipe">
      {inputs && (
        <>
          <div className="Recipe-inputs">
            {inputs.map(input => <ItemHolder name={input} />)}
          </div>
          <div
            className="Recipe-connectors"
            style={{ backgroundImage: `url(/images/input-connectors-${inputs.length}.png)` }}
          />
        </>
      )}
      {props.machine && (
        <>
          <div className="Recipe-item">
            <ItemHolder name={props.machine} />
          </div>
          <div
            className="Recipe-connectors"
            style={{ backgroundImage: `url(/images/output-arrow.png)` }}
          />
        </>
      )}
      <div className="Recipe-item">
        <ItemHolder name={props.item} />
      </div>
      {outputs && (
        <>
          <div
            className="Recipe-connectors"
            style={{ backgroundImage: `url(/images/output-connectors-${outputs.length}.png)` }}
          />
          <div className="Recipe-outputs">
            {outputs.map(output => <ItemHolder name={output} />)}
          </div>
        </>
      )}
    </div>
  );
}

function ItemHolder({ name }) {
  return (
    <a className="ItemHolder" href={`/wiki/items/${name}`}>
      <ItemIcon name={name} />
    </a>
  )
}