import React from 'react';
import { Helmet } from 'react-helmet';
import { MDXProvider } from '@mdx-js/react';
import { Item } from './Item';
import { ItemHeading } from './ItemHeading';
import { ResponsiveImage } from './ResponsiveImage';
import { FullWidthImage } from './FullWidthImage';
import { BreakpointImage } from './BreakpointImage';
import { Header } from './Header';
import { Footer } from './Footer';
import { Recipe } from './Recipe';

import '../styles/index.css';
import '../styles/code.css';

export default (props) => {
  const { title, item, description } = props.pageContext.frontmatter;

  return (
    <>
      <Header />
      <main>
        <Helmet>
          <title>{title}</title>
          <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </Helmet>

        {item ? (
          <ItemHeading name={item}>
            <h1>{title}</h1>
            <h3>{description}</h3>  
          </ItemHeading>
        ) : (
          title ? <h1>{title}</h1> : null
        )}
        
        <MDXProvider
          components={{
            img: Image,
            item: Item,
            ItemHeading,
            ResponsiveImage,
            FullWidthImage,
            BreakpointImage,
            Recipe,
            Img,
          }}
        >
          {props.children}
        </MDXProvider>
        {/* <Footer /> */}
      </main>
    </>
  );
}

function Image(props) {
  return (
    <span style={{ display: 'block', textAlign: 'center' }}>
      <img {...props} />
    </span>
  );
}

function Img(props) {
  return <img {...props} />;
}