import React from 'react';

export function ResponsiveImage({ src, ratio, width, height }) {
  return (
    <div style={{
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100vw',
      minWidth: width[0],
      maxWidth: width[1],
      height: `calc(100vw * ${ratio})`,
      minHeight: height[0],
      maxHeight: height[1],
      backgroundImage: `url('${src}')`,
      backgroundPosition: '50% 0',
      backgroundSize: 'cover',
      margin: '2rem 0',
    }} />
  )
}