import React from 'react';

export function ItemHeading({ name, children }) {
  return (
    <div className="ItemHeading">
      <div className="ItemHeading-img">
        <img src={`/images/items/${name}.png`} />
      </div>
      <div className="ItemHeading-text">
        {children}
      </div>
    </div>
  )
}