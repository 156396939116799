import React from 'react';
import cn from 'classnames';

const colors = {
  'batteries': '#b84014',
  'brass': '#b98430',
  'cadmium': '#a4a5a5',
  'circuit': '#00844f',
  'copper': '#cc3600',
  'gold': '#ffc030',
  'nickel': '#eadca4',
  'processor': '#00844f',
  'silicon': '#ffffff',
  'wire': '#cc3600',
};

export function Item({ children, qty = 1, box = false}) {
  const className = cn('Item', { 'Item--box': box });
  const style = { color: colors[children] };

  return (
    <span className={className} style={style}>
      <ItemIcon name={children} />
      {children}
      {qty > 1 && ` x ${qty}`}
    </span>
  )
}

export function ItemIcon({ name }) {
  return (
    <img src={`/images/items/${name}.png`} />
  );
}